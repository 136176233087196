<template>
  <el-card class="body">
    <el-input
      v-model="activeTimeEntry.title"
      class="new-task-title"
      autofocus
      :placeholder="mainInput"
      @keydown.enter.native.prevent="startTimer"
    />
    <FolderIcon :time-entry="activeTimeEntry" />
    <TagBtn />
    <BillableIcon :time-entry="activeTimeEntry" />

    <el-input
      v-if="timerMode"
      v-model="trackTime"
      class="timer"
    />
    <DateTimeRange
      v-else
      class="manual-mode"
      :time-entry="activeTimeEntry"
    />

    <!--      start button-->
    <i
      v-if="!timerStart && timerMode"
      class="fas fa-play-circle"
      style="font-size: 40px; color: #409eff"
      @click="startTimer"
    />
    <i
      v-else-if="timerStart"
      class="fas fa-stop-circle"
      style="font-size: 40px; color: #ff897a"
      @click="stopTimer"
    />
    <i
      v-else-if="!timerStart && !timerMode"
      style="font-size: 40px; color: #409eff"
      class="fas fa-check-circle"
      @click="saveTimeEntry"
    />

    <div
      v-if="!timerStart"
      class="change-mode"
    >
      <i
        class="fas fa-stopwatch"
        style="margin-bottom: 7px"
        :style="timerMode ? {'color': '#525252'} : ''"
        @click="timerMode = true"
      />
      <i
        class="fas fa-bars"
        :class="timerMode ? '' : 'active-mode'"
        :style="!timerMode ? {'color': '#525252'} : ''"
        @click="timerMode = false"
      />
    </div>
    <OptionsBtn v-else />
  </el-card>
</template>

<script>
import { DateTime } from 'luxon';
import { TimeEntry } from '@timeragent/core';
import { mapGetters } from 'vuex';

export default {
  name: 'PageHeader',
  components: {
    DateTimeRange: () => import('./popovers/DateTimeRange.vue'),
    FolderIcon: () => import('./popovers/FolderBtn.vue'),
    TagBtn: () => import('./popovers/TagBtn.vue'),
    BillableIcon: () => import('./popovers/BillableBtn.vue'),
    OptionsBtn: () => import('./popovers/OptionsBtn.vue'),
  },
  props: {
    activeEntry: {
      type: TimeEntry,
      required: true,
    },
  },
  data() {
    return {
      countTime: 0,
      time: {},
      timerMode: true,
      timerStart: false,
      intervalId: null,
      activeTimeEntry: new TimeEntry(),
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'getActiveTimeEntry',
    ]),
    mainInput() {
      if (!this.timerStart && this.timerMode) {
        return 'What are you working on?';
      }

      if (this.timerStart) {
        return '(no description)';
      }

      return 'What have you done?';
    },
    trackTime() {
      if (this.timerStart) {
        this.calculateTime();

        return `${this.time.hours}:${this.time.min}:${this.time.sec}`;
      }

      return '0:00:00';
    },
    timeForBrowserPageTitle() {
      let message;

      switch (true) {
      case this.countTime < 60000 && this.countTime > 1:
        message = `${this.time.sec} sec | Timer Agent`;
        break;
      case this.countTime < 3.6e+6 && this.countTime >= 60000:
        message = `${this.time.min}:${this.time.sec} min | Timer Agent`;
        break;
      case this.countTime >= 3.6e+6:
        message = `${this.time.hours}:${this.time.min}:${this.time.sec} h | Timer Agent`;
        break;
      default:
        message = 'Tracker | Timer Agent';
      }

      return message;
    },
  },
  watch: {
    async getActiveTimeEntry(value) {
      if (value) {
        if (this.activeTimeEntry.active) {
          await this.stopTimer();
        }

        this.activeTimeEntry = Object.assign(this.activeTimeEntry, {
          ...value,
          active: true,
        });
        await this.startTimer();
      }
    },
  },
  created() {
    if (this.activeTimeEntry?.startTime) {
      this.countTime = Math.abs(Date.now() - new Date(this.activeTimeEntry.startTime).getTime());
      this.timerStart = true;
      this.countTimer();
    }

    if (this.activeEntry) {
      if (this.activeTimeEntry.title === this.activeEntry.title) {
        return;
      }

      this.activeTimeEntry = Object.assign(this.activeTimeEntry, this.activeEntry);
      this.countTime = DateTime.local().toMillis() - DateTime.fromISO(this.activeTimeEntry.startTime).toMillis();
      this.timerStart = true;
      this.countTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    async startTimer() {
      if (this.timerStart) {
        return;
      }

      Object.assign(this.activeTimeEntry, {
        startTime: new Date(),
        active: true,
        userUuid: this.user.uuid,
      });
      await this.activeTimeEntry.save();
      this.timerStart = true;
      this.countTimer();
    },
    async stopTimer() {
      this.timerStart = false;
      this.countTime = 0;
      document.title = 'Tracker | Timer Agent';
      clearInterval(this.intervalId);

      Object.assign(this.activeTimeEntry, { endTime: new Date(), active: false });
      this.$emit('save-new-entry', await this.activeTimeEntry.save());
      this.activeTimeEntry = new TimeEntry();
    },
    async saveTimeEntry() {
      this.$emit('save-new-entry', await this.activeTimeEntry.save());
      this.activeTimeEntry = new TimeEntry();
      this.timerMode = true;
    },
    countTimer() {
      if (this.timerStart) {
        this.intervalId = setInterval(() => {
          this.countTime += 1000;
          document.title = this.timeForBrowserPageTitle;
        }, 1000);
      }
    },
    calculateTime() {
      const tmin = Math.floor((this.countTime % (1000 * 60 * 60)) / (1000 * 60));
      const tsec = Math.floor((this.countTime % (1000 * 60)) / 1000);

      this.time.hours = Math.floor((this.countTime % (1000 * 60 * 60 * 72)) / (1000 * 60 * 60));
      this.time.min = tmin < 10 ? `0${tmin}` : tmin;
      this.time.sec = tsec < 10 ? `0${tsec}` : tsec;
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
  .body {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .body i {
    font-size: 16px;
    color: #909398;
  }

  .body i:hover {
    color: #525252;
    cursor: pointer;
  }

  ::v-deep {
    .el-card {
      transition: none;
    }

    .el-card__body {
      display: grid;
      grid-template-columns: minmax(200px, 1fr) max-content 30px 30px min-content 45px 30px;
      justify-items: center;
      align-items: center;
      grid-gap: 10px;
      padding: 10px;
    }
  }

  .item {
    font-size: 14px;
    padding: 5px 0;
  }

  .timer {
    width: 85px;
  }

  .change-mode {
    display: grid;
  }

  .active-mode {
    color: #525252;
    cursor: default;
  }
</style>
